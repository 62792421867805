import React, { FunctionComponent, useCallback, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    root: {
      width: '100%',
    },
    rootDisplay: {
      display: 'flex',
    },
    sectionDesktop: {
      marginLeft: '20px',
      display: 'flex',
    },
    grow: {
      flexGrow: 1,
    },
    logo: {
      maxWidth: 160,
    },
    menuButtonColor: {
      color: '#F9C85C',
    },
    paper: {
      width: drawerWidth,
    },
    links: {
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    followedP: {
      marginBottom: '0px',
    },
    fontCSS: {
      color: 'black',
      display: 'inline-block',
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  })
));

const DashboardHeader: FunctionComponent = ({ children }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const contentShouldShift = open
    ? ''
    : classes.contentShift;

  return (
    <div className={classes.root}>
      <AppBar style={{ background: '#FFFFFF' }} position="static">
        <Toolbar>
          <IconButton
            id="sidebar-open"
            color="inherit"
            onClick={() => setOpen(true)}
            edge="start"
          >
            <MenuIcon className={classes.menuButtonColor} />
          </IconButton>
          {/* eslint-disable-next-line global-require */}
          <img src={require('./images/RayoLogo.png')} alt="Rayo" className={classes.logo} />
          <div className={classes.grow} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.rootDisplay}>
          <IconButton
            id="sidebar-close"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <List>
          <ListItem button component="a" href="#vision">
            <ListItemText primary="Vision" />
          </ListItem>
          <ListItem button component="a" href="#product">
            <ListItemText primary="Product" />
          </ListItem>
          <ListItem button component="a" href="#contact">
            <ListItemText primary="Contact" />
          </ListItem>
        </List>
      </Drawer>
      <div className={`${classes.content} ${contentShouldShift}`}>
        {children}
      </div>
    </div>
  );
};

export default DashboardHeader;
