import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  button: {
    fontFamily: 'Nunito',
    background: '#FDCB4E',
    '&:hover': {
      background: '#FDCB4E',
    },
    textTransform: 'none',
    borderRadius: 8,
    width: 200,
    fontSize: 20,
  },
  contactButton: {
    fontFamily: 'Nunito',
    background: 'white',
    '&:hover': {
      background: 'white',
    },
    textTransform: 'none',
    borderRadius: 8,
    width: 200,
    marginTop: 20,
    marginBottom: 50,
  },
  card: {
    borderRadius: 15,
    padding: 2,
    [theme.breakpoints.up('md')]: {
      minHeight: '30vw',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '15vw',
      marginTop: '10%'
    },
    boxShadow: '0 3px 5px 2px gray',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  textField: {
    fontFamily: 'Nunito',
    backgroundColor: 'white !important',
    width: '100%',
  },
  icon: {
    fontSize: 80,
  },
  midIcon1: {
    fontSize: 80,
    color: 'orange',
    marginBottom: '50%',
  },
  midIcon2: {
    fontSize: 150,
    color: 'orange',
    marginBottom: '50%',
  }
}));

export default useStyles;
