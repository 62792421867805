import React, { FunctionComponent, useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  Button, Card, CardContent, TextField,
} from '@material-ui/core';
import {
  EventNote, Timeline, EmojiObjectsOutlined, PhoneInTalk, AssignmentTurnedIn, Mail, Schedule
} from '@material-ui/icons';

import DashboardHeader from './components/Layout/DashboardHeader';
import useStyles from './muiStyle';
import '../css/Home.css';

// eslint-disable-next-line global-require
// eslint-disable-next-line @typescript-eslint/no-var-requires
const computerStockImage = require('../assets/rayo_computer_bro_2_30.png');
// import { ReactComponent as Wave } from '../assets/wave1.svg'

const svgStyle = {
  marginTop: '-9%',
  marginBottom: '-3%',
};

export function scrollToContact(): void {
  const contactDiv = document.getElementById('contact');
  /* istanbul ignore else */
  if (contactDiv !== null) {
    contactDiv.scrollIntoView({ behavior: 'smooth' });
  }
}

const Home: FunctionComponent = () => {
  const scrollCallback = useCallback(scrollToContact, []); // callback hook
  const muiStyle = useStyles();
  return (
    <BrowserRouter>
      <DashboardHeader />
      <div className="flex-wrapper">

        <div className="header">
          <div className="row">
            <div className="privy-column">
              <h1 className="big-text">
              Privy to Empowerment
              </h1>
              <Button
                className={muiStyle.button}
                variant="contained"
                id="headerButton"
                onClick={scrollCallback}
              >
              Get in Touch
              </Button>
            </div>
            <div className="image-column">
              <img src={computerStockImage} alt="" />
            </div>
          </div>
        </div>

        <div style={svgStyle}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#FDCB4E"
              fillOpacity="1"
              d="M0,128L48,106.7C96,85,192,43,288,58.7C384,75,480,149,576,202.7C672,256,768,288,
              864,266.7C960,245,1056,171,1152,149.3C1248,128,1344,160,1392,176L1440,192L1440,
              320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,
              320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            />
          </svg>
        </div>

        <div className="row">
          <div className="mid1">
            <div className="column">
              <h1 id="vision" className="kinda-big-text">
                <b>What is the vision for Rayo?</b>
              </h1>
              <h1>
              Rayo is a software tool that enables caretakers to provide quality care to individuals
              with cognitive disabilities through all aspects of their day.
              </h1>
            </div>
          </div>
          <div className="card-wrapper">
            <div className="row">
              <div className="card-column">
                <Card className={muiStyle.card}>
                  <CardContent>
                    <div className="row">
                      <div className="inner-card-column">
                        <EventNote className={muiStyle.icon} />
                      </div>
                      <div className="triple-inner-card-column">
                        <h1>
                        Administrative Help
                        </h1>
                      </div>
                    </div>
                    <h2>
                    Helping scheduling and billing to go smoother and faster
                    so that administrators can focus on interacting with the clients!
                    </h2>
                  </CardContent>
                </Card>
              </div>
              <div className="card-column">
                <Card className={muiStyle.card}>
                  <CardContent>
                    <div className="row">
                      <div className="inner-card-column">
                        <Timeline className={muiStyle.icon} />
                      </div>
                      <div className="triple-inner-card-column">
                        <h1>
                        Progress Visualization
                        </h1>
                      </div>
                    </div>
                    <h2>
                    Visualize the progress of your clients’ goals so you
                    can see what’s working and what isn’t!
                    </h2>
                  </CardContent>
                </Card>
              </div>
              <div className="card-column">
                <Card className={muiStyle.card}>
                  <CardContent>
                    <div className="row">
                      <div className="inner-card-column">
                        <EmojiObjectsOutlined className={muiStyle.icon} />
                      </div>
                      <div className="triple-inner-card-column">
                        <h1>
                        Predicting Curriculums
                        </h1>
                      </div>
                    </div>
                    <h2>
                    Understanding clients and solutions that have worked before to
                    predict curriculums that can reach goals faster!
                    </h2>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>

        <div className="mid2">
          <div className="current-product-row">
            <div className="double-column">
              <h1 id="product" className="kinda-big-text">
                <b>Current Product</b>
              </h1>
              <h1>
              The administrative aspects of the product have been targeted
              first and are currently being developed.
              We have provider/client scheduling and transportation scheduling.
              </h1>
              <h1>
              Now you can spend more time helping your clients and less on paperwork!
              </h1>
            </div>
            <div className="icon-column">
              <PhoneInTalk className={muiStyle.midIcon1} />
              <AssignmentTurnedIn className={muiStyle.midIcon2} />
            </div>
            <div className="icon-column">
              <Mail className={muiStyle.midIcon2} />
              <Schedule className={muiStyle.midIcon1} />
            </div>
          </div>
        </div>

        <div className="contact" id="contact">
          <div className="row">
            <div className="contact-column">
              <h1 id="contact" className="kinda-big-text">
                <b>Contact Us</b>
              </h1>
              <h1>
              Whether you&apos;re a parent who is interested in the system,
              or looking to be involved in the project, let us know!
              </h1>
              <TextField
                InputProps={{ className: muiStyle.textField }}
                label="Your Email Address"
                margin="normal"
                variant="filled"
              />
              <TextField
                InputProps={{ className: muiStyle.textField }}
                label="Your Message"
                margin="normal"
                variant="filled"
                multiline
                rows="6"
              />
              <Button className={muiStyle.contactButton} variant="contained">
              Send
              </Button>
            </div>
          </div>
        </div>

      </div>
    </BrowserRouter>
  );
};

export default Home;
